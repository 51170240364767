import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar/Sidebar';
import firestore from '../../../api/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { Button, Form, Modal } from 'react-bootstrap';
import { Dash, Plus, PlusLg } from "react-bootstrap-icons";
import Table from "../table/Table";
import '../categories/style/categories.css';

function Categories() {
    const [profileProducts, setProfileProducts] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [activeTab, setActiveTab] = useState('profile');
    const [newProduct, setNewProduct] = useState({
        name: '',
        description: '',
        sizes: [],
        prices: [],
        imageUrl: '',
    });
    const [show, setShow] = useState(false);
    const [newSize, setNewSize] = useState('');
    const [newPrice, setNewPrice] = useState('');

    useEffect(() => {
        // Получение данных из коллекции "профиль"
        firestore.collection('profile').get().then((snapshot) => {
            const profileProductsData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setProfileProducts(profileProductsData);
        });

        // Получение данных из коллекции "сопутствующие товары"
        firestore.collection('related').get().then((snapshot) => {
            const relatedProductsData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setRelatedProducts(relatedProductsData);
        });
    }, []);

    const handleAddSize = () => {
        if (newSize.trim() !== '' && !newProduct.sizes.includes(newSize)) {
            setNewProduct({ ...newProduct, sizes: [...newProduct.sizes, newSize], prices: [...newProduct.prices, newPrice] });
            setNewSize('');
            setNewPrice('');
        }
    };

    const handleRemoveSize = (index) => {
        const updatedSizes = [...newProduct.sizes];
        const updatedPrices = [...newProduct.prices];
        updatedSizes.splice(index, 1);
        updatedPrices.splice(index, 1);
        setNewProduct({ ...newProduct, sizes: updatedSizes, prices: updatedPrices });
    };

    const handleAddProduct = () => {
        // Добавление нового товара в текущую активную коллекцию
        const collectionName = activeTab === 'profile' ? 'profile' : 'related';

        // При выборе файла для загрузки
        const file = newProduct.imageFile;
        if (file) {
            const storageRef = firebase.storage().ref(); // Получение ссылки на корневой каталог хранилища
            const imageRef = storageRef.child(`images/${file.name}`); // Создание ссылки на файл в хранилище
            imageRef.put(file) // Загрузка файла в хранилище
                .then((snapshot) => {
                    // Получение URL загруженного изображения
                    return snapshot.ref.getDownloadURL();
                })
                .then((imageUrl) => {
                    // После успешной загрузки изображения, сохранение его URL в Firestore
                    firestore.collection(collectionName).add({
                        name: newProduct.name,
                        description: newProduct.description,
                        sizes: newProduct.sizes,
                        prices: newProduct.prices,
                        imageUrl: imageUrl, // Использование URL изображения
                    })
                        .then(() => {
                            // Очистка формы и обновление списка товаров
                            setNewProduct({
                                name: '',
                                description: '',
                                sizes: [],
                                prices: [],
                                imageUrl: '',
                            });
                            handleClose();
                        })
                        .catch((error) => {
                            console.error('Ошибка при добавлении товара:', error);
                        });
                })
                .catch((error) => {
                    console.error('Ошибка при загрузке изображения:', error);
                });
        } else {
            // Если файл не выбран, добавление товара без изображения
            firestore.collection(collectionName).add({
                name: newProduct.name,
                description: newProduct.description,
                sizes: newProduct.sizes,
                prices: newProduct.prices,
                imageUrl: '', // Пустой URL, если изображение не выбрано
            })
                .then(() => {
                    // Очистка формы и обновление списка товаров
                    setNewProduct({
                        name: '',
                        description: '',
                        sizes: [],
                        prices: [],
                        imageUrl: '',
                    });
                    handleClose();
                })
                .catch((error) => {
                    console.error('Ошибка при добавлении товара:', error);
                });
        }
    };

    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    };

    function handleDescriptionChange(e) {
        setNewProduct({ ...newProduct, description: e.target.value });
    }

    return (
        <div className='categories'>
            <Sidebar/>
            <div style={{width: '80%', marginRight: '50px',float: 'right'}}>
                <h2>Категории</h2>
                <div className='categories-container'>
                    <div className='flex-container'>
                        <div className='categories-container__tabs'>
                            <button
                                className={`categories-container__tabs-tab ${activeTab === 'profile' ? 'active' : ''}`}
                                onClick={() => setActiveTab('profile')}
                            >
                                Профиль
                            </button>
                            <button
                                className={`categories-container__tabs-tab ${activeTab === 'related' ? 'active' : ''}`}
                                onClick={() => setActiveTab('related')}
                            >
                                Сопутствующие товары
                            </button>
                        </div>
                        <Button variant="primary" onClick={handleShow}>
                            <Plus style={{ fontSize: '30' }} />
                            Добавить товар
                        </Button>
                    </div>
                    {activeTab === 'profile' && (
                        <div className='categories-container__selected'>
                            <div>
                                <h3>Профиль</h3>
                            </div>
                            <Table activeTab={activeTab} />
                        </div>
                    )}
                    {activeTab === 'related' && (
                        <div className='categories-container__selected'>
                            <h3>Сопутствующие товары</h3>
                            <Table activeTab={activeTab} />
                        </div>
                    )}

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Добавить товар</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Label className='label'>Название товара</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={newProduct.name}
                                        onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className='label'>Описание товара</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ maxWidth: '500px', wordWrap: 'break-word' }}
                                        rows="4"
                                        value={newProduct.description}
                                        onChange={handleDescriptionChange}
                                        onBlur={handleDescriptionChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className='label'>Размер товара</Form.Label>
                                    <div className="d-flex flex-column">
                                        {newProduct.sizes.map((size, index) => (
                                            <div key={index} className="mb-2">
                                                {size} - {newProduct.prices[index]} ₽
                                                <Button
                                                    style={{marginLeft: '10px'}}
                                                    variant="light" size="sm"
                                                    onClick={() => handleRemoveSize(index)}
                                                >
                                                    <Dash style={{ fontSize: '25'}} />
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            type="text"
                                            placeholder="Размер"
                                            value={newSize}
                                            onChange={(e) => setNewSize(e.target.value)}
                                            className="mr-2"
                                        />
                                        <Form.Control
                                            style={{ marginLeft: '5px' }}
                                            type="text"
                                            placeholder="Цена"
                                            value={newPrice}
                                            onChange={(e) => setNewPrice(e.target.value)}
                                            className="mr-2"
                                        />
                                        <Button variant="light" onClick={handleAddSize} style={{ marginLeft: '5px' }}>
                                            <PlusLg style={{ fontSize: '25' }} />
                                        </Button>
                                    </div>
                                </Form.Group>
                                <Form.Control
                                    className='label'
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            setNewProduct({ ...newProduct, imageFile: file });
                                        }
                                    }}
                                />
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Закрыть
                            </Button>
                            <Button variant="success" onClick={handleAddProduct}>
                                Добавить
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

export default Categories;
