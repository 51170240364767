import React, { useState, useEffect } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import {firebaseConfig} from "../../../api/firebaseConfig";
import '../main/style/main.css';

function VideoCard({ videoUrl, thumbnailUrl, onClick }) {
    return (
        <Card className='card' onClick={onClick} style={{border: 'none'}}>
            <Card.Img variant="top" src={thumbnailUrl} />
        </Card>
    );
}

function Main() {
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
    const [videoData, setVideoData] = useState([
        {
            videoUrl: "video/addItem.mp4",
            thumbnailUrl: "video/thumbnail/addItem.jpg",
        },
        {
            videoUrl: "video/editItem.mp4",
            thumbnailUrl: "video/thumbnail/editItem.jpg",
        },
        {
            videoUrl: "video/deleteItem.mp4",
            thumbnailUrl: "video/thumbnail/deleteItem.jpg",
        },
        {
            videoUrl: "video/addSlide.mp4",
            thumbnailUrl: "video/thumbnail/addSlide.jpg",
        },
        {
            videoUrl: "video/editSlide.mp4",
            thumbnailUrl: "video/thumbnail/editSlide.jpg",
        },
        {
            videoUrl: "video/deleteSlide.mp4",
            thumbnailUrl: "video/thumbnail/deleteSlide.jpg",
        },
    ]);

    useEffect(() => {
        firebase.initializeApp(firebaseConfig);

        const fetchVideoData = async () => {
            const storage = firebase.storage();
            const storageRef = storage.ref();

            const videoDataArray = await Promise.all(
                videoData.map(async (video) => {
                    try {
                        const videoUrl = await storageRef.child(video.videoUrl).getDownloadURL();
                        const thumbnailUrl = await storageRef.child(video.thumbnailUrl).getDownloadURL();
                        console.log("Video URL:", videoUrl);
                        console.log("Thumbnail URL:", thumbnailUrl);
                        return { videoUrl, thumbnailUrl };
                    } catch (error) {
                        console.error("Error fetching URLs:", error);
                        return { videoUrl: "", thumbnailUrl: "" };
                    }
                })
            );

            setVideoData(videoDataArray);
        };
        fetchVideoData();
    }, []);

    const openVideoModal = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
        setShowVideoModal(true);
    };

    const closeVideoModal = () => {
        setSelectedVideoUrl("");
        setShowVideoModal(false);
    };

    return (
        <div className="video">
            <div className='video-container'>
                <div className='video-container__content'>
                    {videoData.map((video, index) => (
                        <VideoCard
                            key={index}
                            videoUrl={video.videoUrl}
                            thumbnailUrl={video.thumbnailUrl}
                            onClick={() => openVideoModal(video.videoUrl)}
                        />
                    ))}
                    <p>Инструкции по использованию функционала DASHBOARD.</p>
                </div>
            </div>

            <Modal show={showVideoModal} onHide={closeVideoModal} size="xl">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <video width="100%" height="100%" controls>
                        <source src={selectedVideoUrl} type="video/mp4" />
                        Ваш браузер не поддерживает видео.
                    </video>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Main;
