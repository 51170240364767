import React, {useRef} from 'react';
import {Nav} from "react-bootstrap";

import '../footer/style/footer.css';
import '../footer/style/@media.css';

import Logo from '../../public/images/logo/logo.svg';
import Place from '../../public/images/icons/geoBlack.svg';
import Mail from '../../public/images/icons/mailBlack.svg';
import Viber from '../../public/images/icons/viber.svg';
import WhatsApp from '../../public/images/icons/whatsApp.svg';

function Footer() {
    const contentRef = useRef(null);

    const handleAnchorClick = (event, yOffset = -111) => {
        event.preventDefault();

        const targetId = event.target.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            const yOffset = -100;
            const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    return(
        <div className='footer'>
            <div className='footer-container'>
                <div className='footer-container__content'>
                    <div className='footer-container__content-logo'>
                        <img
                            src={Logo}
                            height='83'
                            alt='Logo'
                        />
                    </div>
                    <div className='footer-container__content-text'>
                        <p>Позвоните сейчас и сделайте заказ!</p>
                    </div>
                    <div className='footer-container__content-tel'>
                        <Nav.Link href='tel:+73412906120' className='anchor'>+7 (3412) 906-120</Nav.Link>
                        <Nav.Link href='tel:+73412906121' className='anchor'>+7 (3412) 906-121</Nav.Link>
                        <Nav.Link href='tel:+79090606120' className='anchor'>+7 (909) 060-61-20</Nav.Link>
                        <Nav.Link href='tel:+79090606121' className='anchor'>+7 (909) 060-61-21</Nav.Link>
                    </div>
                </div>
                <div className='footer-container__contentSecond'>
                    <div className='footer-container__contentSecond-links'>
                        <Nav.Link
                            href='#catalog'
                            className='footer-container__contentSecond-links__text'
                            onClick={(e) => handleAnchorClick(e)}
                        >
                            Каталог
                        </Nav.Link>
                        <Nav.Link
                            href='#about'
                            className='footer-container__contentSecond-links__text'
                            onClick={(e) => handleAnchorClick(e)}
                        >
                            О компании
                        </Nav.Link>
                        <Nav.Link
                            href='#promotions'
                            className='footer-container__contentSecond-links__text'
                            onClick={(e) => handleAnchorClick(e)}
                        >
                            Акции
                        </Nav.Link>
                        <Nav.Link
                            href='#contacts'
                            className='footer-container__contentSecond-links__text'
                            onClick={(e) => handleAnchorClick(e)}
                        >
                            Контакты
                        </Nav.Link>
                    </div>
                </div>
                <div className='footer-container__contentThird'>
                    <div className='footer-container__contentThird-text'>
                        <img
                            src={Place}
                            height='36'
                            alt='Place'
                        />
                        <p>г.Ижевск, ул.Воткинское шоссе, д.284 М6</p>
                    </div>
                    <div className='footer-container__contentThird-mail'>
                        <img
                            src={Mail}
                            height='36'
                            alt='Place'
                        />
                        <Nav.Link
                            href='mailto:grand20.18@bk.ru'
                            className='footer-container__contentThird-mail__text'
                        >
                            grand20.18@bk.ru
                        </Nav.Link>
                    </div>
                    <div className='footer-container__contentThird-networks'>
                        <div className='footer-container__contentThird-networks__text'>
                            <p>Мы в социальных сетях</p>
                        </div>
                        <div className='footer-container__contentThird-networks__icons'>
                            <Nav.Link
                                href="viber://chat?number=%2B79090606120"
                                className='footer-container__contentThird-networks__icons-icon'>
                                <img
                                    src={Viber}
                                    height=''
                                    alt='Viber'
                                />
                            </Nav.Link>
                            <Nav.Link
                                href="https://wa.me/79090606120?text=Здравствуйте!"
                                className='footer-container__contentThird-networks__icons-icon'>
                                <img
                                    src={WhatsApp}
                                    height=''
                                    alt='WhatsApp'
                                />
                            </Nav.Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
