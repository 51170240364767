import React, {useRef, useState} from 'react';
import {Navbar, Nav, NavDropdown, Button, Collapse} from 'react-bootstrap';
import {AiOutlineMenu} from "react-icons/ai";

import '../header/style/header.css';
import '../header/style/@media.css';

import Logo from '../../public/images/logo/logo.svg';
import Tel from '../../public/images/icons/telephone.svg';
import Calendar from '../../public/images/icons/calendar.svg';
import Place from '../../public/images/icons/geo.svg';
import Mail from '../../public/images/icons/mail.svg';


function Header() {
    const [showDropdown, setShowDropdown] = useState(false);
    const contentRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const handleAnchorClick = (event, yOffset = -111) => {
        event.preventDefault();

        const targetId = event.target.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            const yOffset = -100;
            const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    return(
        <>
            <Navbar sticky="top" expand="xxl" className='header' bg='white' collapseOnSelect>
                <div className='header-content'>
                    <div className='header-content__logo'>
                        <Navbar.Brand href='/'>
                            <img
                                src={Logo}
                                height="83"
                                className="d-inline-block alight-top"
                                alt="Logo"
                            />
                        </Navbar.Brand>
                    </div>
                    <div className='header-content__links'>
                        <Nav.Link
                            href='#catalog'
                            className="anchor"
                            onClick={(e) => handleAnchorClick(e)}
                        >
                            Каталог
                        </Nav.Link>
                        <Nav.Link
                            href='#about'
                            className="anchor"
                            onClick={(e) => handleAnchorClick(e)}
                        >
                            О компании
                        </Nav.Link>
                        <Nav.Link
                            href='#promotions'
                            className="anchor"
                            onClick={(e) => handleAnchorClick(e)}
                        >
                            Акции
                        </Nav.Link>
                        <Nav.Link
                            href='#contacts'
                            className="anchor"
                            onClick={(e) => handleAnchorClick(e)}
                        >
                            Контакты
                        </Nav.Link>
                    </div>
                    <div className='header-content__tel'>
                        <Nav.Link
                            onClick={handleDropdownToggle}

                        >
                            +7 (3412) 906-120
                        </Nav.Link>
                        <NavDropdown
                            title=''
                            className='header-content__tel-drop'
                            show={showDropdown}
                            onClick={handleDropdownToggle}
                        >
                            <div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div>
                                        <img
                                            src={Tel}
                                            height='30'
                                            alt='tel'
                                        />
                                    </div>
                                    <div>
                                        <NavDropdown.Item
                                            className='header-content__tel-drop__item'
                                            href='tel:+73412906120'>
                                            +7 (3412) 906-120
                                        </NavDropdown.Item>
                                        <NavDropdown.Item
                                            className='header-content__tel-drop__item'
                                            href='tel:+73412609121'>+7 (3412) 906-121
                                        </NavDropdown.Item>
                                        <NavDropdown.Item
                                            className='header-content__tel-drop__item'
                                            href='tel:+79090606120'>+7 (909) 060-61-20
                                        </NavDropdown.Item>
                                        <NavDropdown.Item
                                            className='header-content__tel-drop__item'
                                            href='tel:+79090606121'>
                                            +7 (909) 060-61-21
                                        </NavDropdown.Item>
                                    </div>
                                </div>
                                <br/>
                                <hr style={{color: '#8AD0FF'}}/>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div>
                                        <img
                                            src={Calendar}
                                            height='30'
                                            alt='calendar'
                                        />
                                    </div>
                                    <div>
                                        <p
                                            className='header-content__tel-drop__itemTime'>
                                            Пн-Сб: 8:00 - 20:00<br/>
                                            Вс: Выходной
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div>
                                        <img
                                            src={Place}
                                            height='30'
                                            alt='tel'
                                        />
                                    </div>
                                    <div>
                                        <p
                                            className='header-content__tel-drop__itemPlace'>
                                            г. Ижевск,<br/>
                                            ул. Воткинское шоссе,<br/>
                                            д. 284 М6
                                        </p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div>
                                        <img
                                            src={Mail}
                                            height='30'
                                            alt='tel'
                                        />
                                    </div>
                                    <div>
                                        <NavDropdown.Item
                                            className='header-content__tel-drop__itemMail'
                                            href='mailto:grand20.18@bk.ru'>
                                            grand20.18@bk.ru
                                        </NavDropdown.Item>
                                    </div>
                                </div>
                            </div>
                        </NavDropdown>
                    </div>
                    <div className='header-content__regime'>
                        <p>Пн-Сб: 8:00 – 20:00</p>
                    </div>
                    <div className='header-content__collapse'>
                        <Button
                            className='header-content__collapse-btn'
                            variant="link"
                            onClick={() => setOpen(!open)}
                            aria-expanded={open}
                            aria-controls="collapseID"
                        >
                            <AiOutlineMenu className='header-content__collapse-btn__menu'/>
                        </Button>
                        <Collapse in={open}>
                            <div id="collapseID"
                                 style={{
                                     width: 300,
                                     textAlign: 'justify'
                                 }}
                            >
                                <div className='header-content__collapse-btn__menu-list'>
                                    <Nav.Link
                                        href='#catalog'
                                        className="anchor"
                                        onClick={(e) => handleAnchorClick(e)}
                                    >
                                        Каталог
                                    </Nav.Link>
                                    <Nav.Link
                                        href='#about'
                                        className="anchor"
                                        onClick={(e) => handleAnchorClick(e)}
                                    >
                                        О компании
                                    </Nav.Link>
                                    <Nav.Link
                                        href='#promotions'
                                        className="anchor"
                                        onClick={(e) => handleAnchorClick(e)}
                                    >
                                        Акции
                                    </Nav.Link>
                                    <Nav.Link
                                        href='#contacts'
                                        className="anchor"
                                        onClick={(e) => handleAnchorClick(e)}
                                    >
                                        Контакты
                                    </Nav.Link>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </Navbar>
        </>
    )
}

export default Header;
