import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FiLogOut } from "react-icons/fi";
import Logo from '.././../../public/images/logo/logo.svg';

import '../sidebar/style/sidebar.css'

function Sidebar() {
    const location = useLocation();
    const history = useHistory();

    const handleLogout = () => {
        localStorage.removeItem('loggedIn');
        history.push('/login');
    };

    return (
        <div className='sidebar'>
            <img
                src={Logo}
                height='120'
                alt='Logo'
            />
            <div className='sidebar-container'>
                <Link to='/admin' className='sidebar-container__link'>
                    <Link to='/admin'>Главная</Link>
                </Link>
                <Link to='/admin/categories' className='sidebar-container__link'>
                    <Link to='/admin/categories'>Товары</Link>
                </Link>
                <Link to='/admin/promotions' className='sidebar-container__link'>
                    <Link to='/admin'>Акции</Link>
                </Link>
                <Link to='/' className='sidebar-container__link'>
                    <Link to='/'>На сайт</Link>
                </Link>
            </div>
            <Button className='sidebar-btn' variant='light' onClick={handleLogout}>
                <FiLogOut /> Выход
            </Button>
        </div>
    );
}

export default Sidebar;
