import React, { useState, useEffect } from "react";
import firestore from '../../api/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import {Accordion, Button, Carousel} from "react-bootstrap";
import '../promotions/style/promotions.css';
import '../promotions/style/@media.css';
import FirstSlide from "../../public/images/carousels/slides/FirstSlide.png";
import SecondSlide from "../../public/images/carousels/slides/SecondSlide.png";

function Promotions() {
    const [slides, setSlides] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        // Загрузка слайдов из Firestore
        firestore.collection("promotions").onSnapshot((snapshot) => {
            const slidesData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setSlides(slidesData);
        });
    }, []);

    const handleSelect = (selectedIndex, e) => {
        setActiveIndex(selectedIndex);
    };

    return (
        <div className='promo' id='promotions'>
            <div className='promo-container'>
                <div className='promo-container__title'>
                    <h2>Акции</h2>
                </div>
                <div className='promo-container__content'>
                    <div className='promo-container__content-carousel'>
                        <Carousel
                            activeIndex={activeIndex}
                            onSelect={handleSelect}
                            indicators={true}
                            className='promo-container__content-carousel__content'
                        >
                            {slides.map((slide, index) => (
                                <Carousel.Item key={slide.id} className='promo-container__content-carousel__content-item'>
                                    <img
                                        src={slide.imageUrl}
                                        alt={`Slide ${index + 1}`}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    <div className='promo-container__content-promot'>
                        <Accordion defaultActiveKey="0" className='promo-container__content-promot__accordion'>
                            {slides.map((slide, index) => (
                                <Accordion.Item key={slide.id} eventKey={index.toString()}>
                                    <Accordion.Header>Посмотреть акцию</Accordion.Header>
                                    <Accordion.Body>
                                        <img
                                            className='promo-container__content-promot__accordion-img'
                                            src={slide.imageUrl}
                                            alt={`Slide ${index + 1}`}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Promotions;
