import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import firebase from "firebase/compat/app";
import { firebaseConfig } from "../../../api/firebaseConfig";
import {Button, Form} from "react-bootstrap";

import '../auth/style/auth.css'

// Инициализация Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

function Auth() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const history = useHistory();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, email, password);
            setLoggedIn(true);
            localStorage.setItem("loggedIn", "true");
        } catch (error) {
            let errorMessage = "Произошла ошибка при входе";
            if (error.code === 'auth/invalid-login-credentials') {
                errorMessage = 'Неверный email или пароль';
            }
            setError(errorMessage);
            console.log(error)
        }
    }

    useEffect(() => {
        // Проверка состояния аутентификации при загрузке компонента
        const isLoggedIn = localStorage.getItem("loggedIn") === "true";
        if (isLoggedIn) {
            setLoggedIn(true);
        }
    }, []);

    if (loggedIn) {
        return <Redirect to="/admin" />;
    }

    return (
        <div className='login'>
            <h2>Вход</h2>
            {error && <p>{error}</p>}
            <Form onSubmit={handleLogin} className='login-form'>
                <Form.Group className='login-form__email' controlId="formBasicEmail">
                    <Form.Control
                        className='login-form__email-input'
                        type="email"
                        placeholder="Ваш email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Form.Group>
                <Form.Group className='login-form__password'>
                    <Form.Control
                        className='login-form__password-input'
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </Form.Group>
                <Button className='login-form__button' variant="primary" type="submit">
                    Войти
                </Button>
            </Form>
        </div>
    )
}

export default Auth;
