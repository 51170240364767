import React from "react";
import Header from "../../components/header/Header";
import Carousels from "../../components/carousel/Carousels";
import About from "../../components/about/About";
import Promotions from "../../components/promotions/Promotions";
import CallBackForm from "../../components/callBackForm/CallBackForm";
import Contacts from "../../components/contacts/Contacts";
import Footer from "../../components/footer/Footer";
import Catalog from "../../components/catalog/Catalog";


function Main() {

    return(
        <>
            <Header/>
            <Carousels/>
            <Catalog/>
            <About/>
            <Promotions/>
            <CallBackForm/>
            <Contacts/>
            <Footer/>
        </>
    )
}

export default Main;
