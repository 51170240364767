import React, {useRef} from "react";

import '../about/style/about.css'
import '../about/style/@media.css'

import Rub from '../../public/images/icons/rub.svg'
import Calen from '../../public/images/icons/calen.svg'
import Calc from '../../public/images/icons/calc.svg'

function About() {
    const items = useRef([]);

    const handleMouseMove = (event, itemIndex) => {
        const item = items.current[itemIndex];
        const itemRect = item.getBoundingClientRect();
        const itemCenterX = itemRect.left + itemRect.width / 2;
        const itemCenterY = itemRect.top + itemRect.height / 2;

        const rotateX = (event.clientY - itemCenterY) / 50;
        const rotateY = (event.clientX - itemCenterX) / 50;

        item.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    const handleMouseLeave = (itemIndex) => {
        const item = items.current[itemIndex];
        item.style.transform = 'perspective(1000px) rotateX(0deg) rotateY(0deg)';
    };
    return(
        <div className='about' id='about'>
            <div className='about-container'>
                <div className='about-container__about'>
                    <div className='about-container__about-title'>
                        <h2 className='about-container__about-title__text'>Компания<br/>«Гранд» </h2>
                    </div>
                    <div className='about-container__about-paragraph'>
                        <p className='about-container__about-paragraph__text'>Мы производим оцинкованный профиль и комплектующие
                            для вентилируемого фасада. В производстве используются
                            проверенные на практике технологии и высококачественное
                            сырье. Наши специалисты обладают большим опытом и
                            применяют знания в области строительства и эксплуатации
                            зданий для усовершенствования фасадных конструкций.
                        </p>
                    </div>
                </div>
                <div className='about-container__content'>
                    <div className='about-container__content-item'
                         ref={(element) => { items.current[0] = element; }}
                         onMouseMove={(event) => handleMouseMove(event, 0)}
                         onMouseLeave={() => handleMouseLeave(0)}>
                        <img
                            src={Rub}
                            height='103'
                            alt='Rub'
                        />
                        <p className='about-container__content-item__text'>Профиль и комплектующие для вентилируемого фасада по выгодной цене</p>
                    </div>
                    <div className='about-container__content-item'
                         ref={(element) => { items.current[1] = element; }}
                         onMouseMove={(event) => handleMouseMove(event, 1)}
                         onMouseLeave={() => handleMouseLeave(1)}>
                        <img
                            src={Calen}
                            height='103'
                            alt='Rub'
                        />
                        <p className='about-container__content-item__text'>Минимальные сроки поставки и экономию времени</p>
                    </div>
                    <div className='about-container__content-item'
                         ref={(element) => { items.current[2] = element; }}
                         onMouseMove={(event) => handleMouseMove(event, 2)}
                         onMouseLeave={() => handleMouseLeave(2)}>
                        <img
                            src={Calc}
                            height='103'
                            alt='Rub'
                        />
                        <p className='about-container__content-item__text'>Удобство при монтаже за счет индивидуальных размеров</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
