import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Main from "./pages/main/Main";
import Admin from "./pages/admin/Admin";
import Auth from "./pages/admin/auth/Auth";
import PrivateRoute from "../src/routes/PrivateRoute";
import Categories from "./components/admin/categories/Categories";
import Product from "./components/products/product/Product";
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';
import './index.css';
import PromotionsDashboard from "./components/admin/promotions/PromotionsDashboard";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <React.StrictMode>
            <Switch>
                <Route exact path="/" component={Main} />
                <Route exact path="/login" component={Auth} />
                <Route exact path="/product/:id" component={Product} />
                <PrivateRoute exact path="/admin/categories" component={Categories} />
                <PrivateRoute exact path="/admin/promotions" component={PromotionsDashboard} />
                <PrivateRoute exact path="/admin" component={Admin} />
            </Switch>
        </React.StrictMode>
    </BrowserRouter>
);

reportWebVitals();
