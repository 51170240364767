import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

export const firebaseConfig = {
    apiKey: "AIzaSyDG-Os1PobDWORQznE00-uGgQbfNSLBrec",
    authDomain: "fasad18izh.firebaseapp.com",
    projectId: "fasad18izh",
    storageBucket: "fasad18izh.appspot.com",
    databaseURL: "",
    messagingSenderId: "171905326580",
    appId: "1:171905326580:web:73e3069780c5b9d379ada1",
    measurementId: "G-FJW9W5TB7R"
};

// Инициализация Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// Получение доступа к Firestore
const firestore = firebase.firestore();
const storage = firebase.storage();

export default firestore;

