import React, { useState, useEffect } from 'react';
import firestore from '../../../api/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import {Trash3Fill, PencilSquare, Plus, Dash, PlusLg} from "react-bootstrap-icons";
import { Button, Modal, Form } from "react-bootstrap";
import '../table/style/table.css'

function Table({ activeTab }) {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null); // Выбранный товар для редактирования
    const [showModal, setShowModal] = useState(false); // Флаг видимости модального окна
    const [showDeleteModal, setShowDeleteModal] = useState(false); // Флаг видимости модального окна подтверждения удаления
    const [productToDelete, setProductToDelete] = useState(null); // Товар, который будет удален


    useEffect(() => {
        const collectionName = activeTab === 'profile' ? 'profile' : 'related';
        // Получение данных из выбранной коллекции
        firestore.collection(collectionName).onSnapshot((snapshot) => {
            const productsData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setProducts(productsData);
        });
    }, [activeTab]);

    // Функция для удаления товара
    const handleDeleteProduct = (productId) => {
        // Определение имени коллекции на основе активной вкладки
        const collectionName = activeTab === 'profile' ? 'profile' : 'related';

        // Удаление товара из коллекции по его ID
        firestore.collection(collectionName).doc(productId).delete()
            .catch((error) => {
                console.error('Ошибка при удалении товара:', error);
            });
    };

    // Функция для открытия модального окна редактирования
    const handleEditProduct = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    // Функция для обновления товара
    const handleUpdateProduct = async () => {
        if (selectedProduct) {
            const collectionName = activeTab === 'profile' ? 'profile' : 'related';

            try {
                if (selectedProduct.imageFile) {
                    // Если выбрано новое изображение, загрузка его и обновление ссылки в Firestore
                    const downloadURL = await uploadImageToStorage(selectedProduct.imageFile);

                    // Обновление выбранного продукта с новым URL изображения
                    const updatedProduct = {
                        ...selectedProduct,
                        imageUrl: downloadURL,
                    };
                    delete updatedProduct.imageFile;
                    // Обновление документа в Firestore
                    await firestore.collection(collectionName).doc(selectedProduct.id).update(updatedProduct);
                } else {
                    await firestore.collection(collectionName).doc(selectedProduct.id).update(selectedProduct);
                }

                // Обновление списка товаров после успешного редактирования
                const updatedProducts = products.map((product) =>
                    product.id === selectedProduct.id ? selectedProduct : product
                );
                setProducts(updatedProducts);
                setSelectedProduct(null);
                setShowModal(false);
            } catch (error) {
                console.error('Ошибка при загрузке и обновлении изображения:', error);
            }
        }
    };

    // Функция для загрузки фото товара
    const uploadImageToStorage = (file) => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();
            const imageRef = storageRef.child(`images/${file.name}`);

            imageRef.put(file)
                .then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve(downloadURL);
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    // Функция для открытия модального окна подтверждения удаления
    const handleShowDeleteModal = (product) => {
        setProductToDelete(product);
        setShowDeleteModal(true);
    };

    // Функция для закрытия модального окна подтверждения удаления
    const handleCloseDeleteModal = () => {
        setProductToDelete(null);
        setShowDeleteModal(false);
    };

    // Функция для подтверждения удаления товара
    const handleConfirmDelete = () => {
        if (productToDelete) {
            handleDeleteProduct(productToDelete.id);
            handleCloseDeleteModal();
        }
    };

    return (
        <div>
            <table className="table">
                <thead>
                <tr>
                    <th>Фото</th>
                    <th>Название</th>
                    <th>Описание</th>
                    <th>Размеры</th>
                    <th>Цены</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                {products.map((product) => (
                    <tr key={product.id}>
                        <td>
                            {product.imageUrl && (
                                <img src={product.imageUrl} alt={product.name} width="50" />
                            )}
                        </td>
                        <td style={{width: '250px'}}>{product.name}</td>
                        <td style={{width: '520px'}}>{product.description}</td>
                        <td style={{width: '210px'}}>
                            {Array.isArray(product.sizes) ? (
                                <ul>
                                    {product.sizes.map((size, index) => (
                                        <li key={index}>
                                            {size}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div>Размеры отсутствуют</div>
                            )}
                        </td>
                        <td>
                            {Array.isArray(product.prices) ? (
                                <ul>
                                    {product.prices.map((price, index) => (
                                        <li key={index}>
                                            {price} ₽
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div>Цены отсутствуют</div>
                            )}
                        </td>
                        <td>
                            <Button
                                className='table-btn'
                                variant='primary'
                                onClick={() => handleEditProduct(product)}
                            >
                                <PencilSquare/>
                            </Button>
                            <Button
                                className='table-btn'
                                variant='danger'
                                onClick={() => handleShowDeleteModal(product)}
                            >
                                <Trash3Fill/>
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Редактировать товар</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedProduct && (
                        <Form>
                            <Form.Group controlId="editProductName">
                                <Form.Label className='label'>Название</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={selectedProduct.name}
                                    onChange={(e) => setSelectedProduct({ ...selectedProduct, name: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="editProductDescription">
                                <Form.Label className='label'>Описание</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    style={{ maxWidth: '500px', wordWrap: 'break-word' }}
                                    rows="4"
                                    value={selectedProduct.description}
                                    onChange={(e) => setSelectedProduct({ ...selectedProduct, description: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="editProductSizes">
                                <Form.Label className='label'>Размеры / Цены</Form.Label>
                                {selectedProduct.sizes.map((size, index) => (
                                    <div key={index} className="d-flex align-items-center mb-2">
                                        <Form.Control
                                            type="text"
                                            placeholder="Размер"
                                            value={size}
                                            onChange={(e) => {
                                                const updatedSizes = [...selectedProduct.sizes];
                                                updatedSizes[index] = e.target.value;
                                                setSelectedProduct({ ...selectedProduct, sizes: updatedSizes });
                                            }}
                                            className="mr-2"
                                        />
                                        <Form.Control
                                            style={{marginLeft: '5px'}}
                                            type="text"
                                            placeholder="Цена"
                                            value={selectedProduct.prices[index]}
                                            onChange={(e) => {
                                                const updatedPrices = [...selectedProduct.prices];
                                                updatedPrices[index] = e.target.value;
                                                setSelectedProduct({ ...selectedProduct, prices: updatedPrices });
                                            }}
                                            className="mr-2"
                                        />
                                        <Button
                                            style={{marginLeft: '5px'}}
                                            variant='light'
                                            onClick={() => {
                                                const updatedSizes = [...selectedProduct.sizes];
                                                const updatedPrices = [...selectedProduct.prices];

                                                // Удалить размер и соответствующую цену по индексу
                                                updatedSizes.splice(index, 1);
                                                updatedPrices.splice(index, 1);

                                                setSelectedProduct({
                                                    ...selectedProduct,
                                                    sizes: updatedSizes,
                                                    prices: updatedPrices,
                                                });
                                            }}
                                        >
                                            <Dash style={{ fontSize: '25' }}/>
                                        </Button>
                                    </div>
                                ))}
                                <Button
                                    variant='light'
                                    onClick={() => {
                                        const updatedSizes = [...selectedProduct.sizes, ''];
                                        const updatedPrices = [...selectedProduct.prices, ''];
                                        setSelectedProduct({
                                            ...selectedProduct,
                                            sizes: updatedSizes,
                                            prices: updatedPrices,
                                        });
                                    }}
                                >
                                    <PlusLg style={{ fontSize: '25' }}/>
                                </Button>
                            </Form.Group>
                            <Form.Group controlId="editProductImage">
                                <Form.Label className='label'>Фото товара</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            setSelectedProduct({ ...selectedProduct, imageFile: file });
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Закрыть
                    </Button>
                    <Button variant="primary" onClick={handleUpdateProduct}>
                        Сохранить
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Подтвердите удаление товара</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {productToDelete && (
                        <p>Вы уверены, что хотите удалить товар "{productToDelete.name}"?</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Отмена
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Удалить
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Table;
