import React from "react";
import { useHistory, Switch, Route } from "react-router-dom";
import Sidebar from "../../components/admin/sidebar/Sidebar";
import Categories from "../../components/admin/categories/Categories";
import PromotionsDashboard from "../../components/admin/promotions/PromotionsDashboard";
import Main from "../../components/admin/main/Main";

function Admin() {
    const history = useHistory();

    return (
        <div className="admin-container">
            <div className="admin-content">
                <Sidebar />
                <Switch>
                    <Route exact path="/admin"/>
                    <Route exact path="/admin/categories" component={Categories}/>
                    <Route exact path="/admin/promotions" component={PromotionsDashboard}/>
                </Switch>
                <div>
                    <Main/>
                </div>
            </div>
        </div>
    );
}

export default Admin;
