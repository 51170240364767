import React, { useState, useEffect } from 'react';
import firestore from '../../api/firestore';
import { Link } from "react-router-dom";
import '../catalog/style/catalog.css';
import '../catalog/style/@media.css';

function Catalog() {
    const [activeCategory, setActiveCategory] = useState('profile');
    const [products, setProducts] = useState([]);
    const isSmallScreen = window.innerWidth <= 1200;

    useEffect(() => {
        firestore
            .collection(activeCategory)
            .get()
            .then((querySnapshot) => {
                const productsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setProducts(productsData);
            })
            .catch((error) => {
                console.error('Ошибка при получении данных из Firestore:', error);
            });
    }, [activeCategory]);

    const handleCategoryChange = (category) => {
        setActiveCategory(category);
    };

    // const handleMouseEnter = () => {
    //     if (isSmallScreen) {
    //         document.body.style.overflow = 'auto';
    //     } else {
    //         document.body.style.overflow = 'hidden';
    //     }
    // };
    //
    // const handleMouseLeave = () => {
    //     const isCursorInsideProductBlock = document.querySelector('.catalog-container__content-products__product:hover');
    //     if (!isCursorInsideProductBlock) {
    //         document.body.style.overflow = 'auto';
    //     }
    // };

    const getMinimumPrice = (prices) => {
        if (!Array.isArray(prices) || prices.length === 0) {
            return '';
        }

        const minimumPrice = Math.min(...prices);
        return ` от ${minimumPrice} ₽`;
    };

    return (
        <div className='catalog' id='catalog'>
            <div className='catalog-container'>
                {/*<h2>Каталог</h2>*/}
                <div className='catalog-container__content'>
                    <div className='flex'>
                        <h2>Каталог</h2>
                        <div className='catalog-container__content-tabs'>
                            <div className='catalog-container__content-tabs__tab'>
                                <button
                                    className={activeCategory === 'profile' ? 'active' : ''}
                                    onClick={() => handleCategoryChange('profile')}
                                >
                                    Профиль
                                </button>
                            </div>
                            <div className='catalog-container__content-tabs__tab'>
                                <button
                                    className={activeCategory === 'related' ? 'active' : ''}
                                    onClick={() => handleCategoryChange('related')}
                                >
                                    Сопутствующие товары
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className='catalog-container__content-products'
                        // onMouseEnter={handleMouseEnter}
                        // onMouseLeave={handleMouseLeave}
                    >
                        {products.map((product) => (
                            <Link key={product.id} to={`/product/${product.id}`}>
                                <div className='catalog-container__content-products__product'>
                                    <div className='catalog-container__content-products__product-flexOne'>
                                        <img src={product.imageUrl} alt={product.name} />
                                        <h3>{product.name}</h3>
                                    </div>
                                    <div className='catalog-container__content-products__product-flexTwo'>
                                        {Array.isArray(product.sizes) ? (
                                            <div className='size-list'>
                                                {product.sizes.map((size, index) => (
                                                    <div key={index} className='size-item'>{size}</div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className='size-list'>
                                                <div className='size-item'>Размеры отсутствуют</div>
                                            </div>
                                        )}
                                        <p>
                                            <span className='catalog-container__content-products__product-flexTwo__price'>
                                                {getMinimumPrice(product.prices)}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Catalog;
