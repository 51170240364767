import React, { useState, useEffect } from "react";
import firestore from "../../../api/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import {Modal, Button, Form} from "react-bootstrap";
import {PencilSquare, Plus, Trash3Fill} from "react-bootstrap-icons";
import Sidebar from "../sidebar/Sidebar";
import '../promotions/style/promotionDashboard.css'

function PromotionsDashboard() {
    const [imageUrl, setImageUrl] = useState("");
    const [slides, setSlides] = useState([]);
    const [editImageId, setEditImageId] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [editedImageUrl, setEditedImageUrl] = useState("");
    const [editedImageFile, setEditedImageFile] = useState(null);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [addButtonLabel, setAddButtonLabel] = useState("Добавить");
    const [deleteImageId, setDeleteImageId] = useState(null);
    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);

    useEffect(() => {
        // Загрузка слайдов из Firestore
        firestore.collection("promotions").onSnapshot((snapshot) => {
            const slidesData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setSlides(slidesData);
        });
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const storageRef = firebase.storage().ref();
            const imageRef = storageRef.child(`promotions/${file.name}`);

            // Загрузка файла в Firebase Storage
            imageRef
                .put(file)
                .then((snapshot) => {
                    return snapshot.ref.getDownloadURL();
                })
                .then((downloadURL) => {
                    setImageUrl(downloadURL);
                })
                .catch((error) => {
                    console.error("Ошибка при загрузке изображения:", error);
                });
        }
    };

    const handleAddSlide = () => {
        if (imageUrl) {
            setAddButtonLabel("Загрузка"); // Изменяем надпись на кнопке
            // Добавление нового слайда с URL изображения в Firestore
            firestore
                .collection("promotions")
                .add({
                    imageUrl: imageUrl,
                })
                .then(() => {
                    setImageUrl("");
                    console.log("Фото успешно добавлено");
                    setAddModalVisible(false); // Закрываем модальное окно после добавления слайда
                    setAddButtonLabel("Добавить"); // Восстанавливаем исходную надпись на кнопке
                })
                .catch((error) => {
                    console.error("Ошибка при добавлении слайда:", error);
                    setAddButtonLabel("Добавить"); // Восстанавливаем исходную надпись на кнопке
                });
        }
    };

    const handleEditImage = (imageId, imageUrl) => {
        setEditImageId(imageId);
        setEditedImageUrl(imageUrl);
        setModalVisible(true);
    };

    const handleSaveEdit = () => {
        if (editedImageFile) {
            const storageRef = firebase.storage().ref();
            const imageRef = storageRef.child(`promotions/${editedImageFile.name}`);

            // Загрузка нового файла в Firebase Storage
            imageRef
                .put(editedImageFile)
                .then((snapshot) => {
                    return snapshot.ref.getDownloadURL();
                })
                .then((downloadURL) => {
                    // Обновление URL изображения в Firestore
                    firestore
                        .collection("promotions")
                        .doc(editImageId)
                        .update({
                            imageUrl: downloadURL,
                        })
                        .then(() => {
                            setEditImageId(null);
                            setModalVisible(false);
                            setEditedImageFile(null);
                        })
                        .catch((error) => {
                            console.error("Ошибка при обновлении слайда:", error);
                        });
                })
                .catch((error) => {
                    console.error("Ошибка при загрузке нового изображения:", error);
                });
        } else {
            // Если нет нового файла, сохраните только URL
            firestore
                .collection("promotions")
                .doc(editImageId)
                .update({
                    imageUrl: editedImageUrl,
                })
                .then(() => {
                    setEditImageId(null);
                    setModalVisible(false);
                })
                .catch((error) => {
                    console.error("Ошибка при обновлении слайда:", error);
                });
        }
    };

    const handleDeleteImage = (imageId) => {
        // Удаление слайда из Firestore
        firestore
            .collection("promotions")
            .doc(imageId)
            .delete()
            .then(() => {
                setConfirmDeleteModalVisible(false); // Закрыть модальное окно после успешного удаления
            })
            .catch((error) => {
                console.error("Ошибка при удалении слайда:", error);
            });
    };

    const handleImageFileChange = (e) => {
        const file = e.target.files[0];
        setEditedImageFile(file);
    };

    const handleAddSlideModal = () => {
        setAddModalVisible(true);
    };

    const handleConfirmDelete = (imageId) => {
        setDeleteImageId(imageId);
        setConfirmDeleteModalVisible(true);
    };

    return (
        <div>
            <Sidebar/>
            <div className='promotion'>
                <h2>Акции</h2>
                <div className='promotion-container'>
                    <div className='promotion-container__title'>
                        <h3>Слайды:</h3>
                        <Button
                            onClick={handleAddSlideModal}
                        >
                            <Plus style={{ fontSize: '30' }} />
                            Добавить слайд
                        </Button>
                    </div>
                    <div className='promotion-container__table'>
                        <table className='table' style={{marginTop: '30px'}}>
                            <thead>
                            <tr>
                                <th style={{textAlign: 'left'}}>Изображение</th>
                                <th style={{textAlign: 'right'}}>Действия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {slides.map((slide) => (
                                <tr key={slide.id}>
                                    <td style={{textAlign: 'left'}}>
                                        <img
                                            src={slide.imageUrl}
                                            alt={`Slide ${slide.id}`}
                                            width="200"
                                        />
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        <Button
                                            className='promotion-container__table-btn'
                                            variant='primary'
                                            onClick={() => handleEditImage(slide.id, slide.imageUrl)}
                                        >
                                            <PencilSquare/>
                                        </Button>
                                        <Button
                                            className='promotion-container__table-btn'
                                            variant='danger'
                                            onClick={() => handleConfirmDelete(slide.id)}
                                        >
                                            <Trash3Fill/>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Редактировать фото</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={handleImageFileChange}
                            />
                            <img
                                style={{marginTop: '20px', borderRadius: '15px'}}
                                src={editedImageUrl}
                                alt="Edited"
                                width="200"
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalVisible(false)}>
                            Закрыть
                        </Button>
                        <Button variant="primary" onClick={handleSaveEdit}>
                            Сохранить
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={addModalVisible} onHide={() => setAddModalVisible(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Добавить слайд</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Label>Загрузить изображение:</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setAddModalVisible(false)}>
                            Закрыть
                        </Button>
                        <Button variant="primary" onClick={handleAddSlide}>
                            {addButtonLabel}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={confirmDeleteModalVisible} onHide={() => setConfirmDeleteModalVisible(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Подтвердите удаление слайда</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Вы уверены, что хотите удалить этот слайд?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setConfirmDeleteModalVisible(false)}>
                            Отмена
                        </Button>
                        <Button variant="danger" onClick={() => handleDeleteImage(deleteImageId)}>
                            Удалить
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            </div>
    );
}

export default PromotionsDashboard;
