import React, {useState} from "react";
import {Carousel, Button, Nav, Accordion, Modal} from "react-bootstrap";

import { ReactComponent as PrevIcon } from '../../public/images/icons/prev.svg';
import { ReactComponent as NextIcon } from '../../public/images/icons/next.svg';

import FirstSlide from '../../public/images/carousels/slides/FirstSlide.png';
import SecondSlide from '../../public/images/carousels/slides/SecondSlide.png';

import './style/carousels.css'
import '../carousel/style/@media.css';
import ModalCallBack from "../callBackForm/modalCallBack/ModalCallBack";


function Carousels() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return(
        <div>
            <div>
                <Carousel
                    prevIcon={<PrevIcon className='prev'/>}
                    nextIcon={<NextIcon className='next'/>}
                    indicators={false}
                    className='carousel'
                >
                    <Carousel.Item className="carousel-item">
                        <img
                            src={FirstSlide}
                            alt='First Slide'
                        />
                        <Carousel.Caption className='carousel-item__content'>
                            <p>
                                <span className='carousel-item__content-span1'>ПРОФИЛЬ И КОМПЛЕКТУЮЩИЕ</span><br/> ДЛЯ ВЕНТИЛИРУЕМОГО ФАСАДА<br/>
                                <span className='carousel-item__content-span2'> от производителя с гарантией качества</span>
                            </p>
                            <Nav.Link href='#'>
                                <Button
                                    onClick={handleShow}
                                    className='carousel-item__content-btn'
                                    variant="primary">
                                    <span className='carousel-item__content-btn__text'>ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ</span>
                                </Button>
                            </Nav.Link>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item className="carousel-item">
                        <img
                            src={SecondSlide}
                            alt='Second Slide'
                        />
                        <Carousel.Caption className='carousel-item__content'>
                            <h2>ВЕНТИЛИРУЕМЫЕ ФАСАДЫ – СОВРЕМЕННАЯ<br/>
                                ТЕХНОЛОГИЯ ВНЕШНЕЙ ОТДЕЛКИ ЗДАНИЙ</h2>
                            <div className='carousel-item__content-text'>
                            <span className='carousel-item__content-text__span'>
                                Особенности конструкций позволяют воздуху свободно циркулировать<br/>
                                между стеной и внешней обшивкой, удаляя конденсат.<br/>
                                За счет этого снижается теплопроводность здания и обеспечивается<br/>
                                благоприятный микроклимат внутри помещений.
                            </span>
                            </div>
                            <Nav.Link href='#' >
                                <Button
                                    onClick={handleShow}
                                    className='carousel-item__content-btn'
                                    variant="primary">
                                    <span className='carousel-item__content-btn__text'>УЗНАТЬ БОЛЬШЕ</span>
                                </Button>
                            </Nav.Link>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className='main'>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <span className='main-title'>
                                ПРОФИЛЬ И КОМПЛЕКТУЮЩИЕ<br/>
                                ДЛЯ ВЕНТИЛИРУЕМОГО ФАСАДА<br/>
                                от производителя с гарантией качества
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <img
                                src={FirstSlide}
                                width='90%'
                                alt='Profile'
                            />
                            <Button onClick={handleShow} className='main-btn'>
                                Получить предложение
                            </Button>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <span className='main-title'>
                                ВЕНТИЛИРУЕМЫЕ ФАСАДЫ – СОВРЕМЕННАЯ<br/>
                                ТЕХНОЛОГИЯ ВНЕШНЕЙ ОТДЕЛКИ ЗДАНИЙ
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <span className='main-text'>
                                Особенности конструкций позволяют воздуху свободно циркулировать<br/>
                                между стеной и внешней обшивкой, удаляя конденсат.<br/>
                                За счет этого снижается теплопроводность здания и обеспечивается<br/>
                                благоприятный микроклимат внутри помещений.
                            </span>
                            <img
                                src={SecondSlide}
                                width='90%'
                                alt='Profile'
                            />
                            <Button className='main-btn' onClick={handleShow}>
                                Узнать подробнее
                            </Button>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Форма обратной связи</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ModalCallBack/>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default Carousels;
