import React from "react";
import {Nav} from "react-bootstrap";
import {YMaps, Map, ZoomControl, Placemark} from '@pbe/react-yandex-maps';

import '../contacts/style/contacts.css';
import '../contacts/style/@media.css'

import Tel from '../../public/images/icons/telephoneBlack.svg';
import Place from '../../public/images/icons/geoBlack.svg';
import Mail from '../../public/images/icons/mailBlack.svg';

function Contacts() {

    return(
        <div className='contacts' id='contacts'>
            <div className='contacts-title'>
                <h2>Наши контакты:</h2>
            </div>
            <div className='contacts-container'>
                <div className='contacts-container__content'>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <img
                            style={{marginTop: '10px', marginRight: '30px'}}
                            src={Tel}
                            height='50'
                            alt=''
                        />
                        <div className='contacts-container__content-tel'>
                            <Nav.Link href='tel:+73412906120' className='anchor'>+7 (3412) 906-120</Nav.Link>
                            <Nav.Link href='tel:+73412906121' className='anchor'>+7 (3412) 906-121</Nav.Link>
                            <Nav.Link href='tel:+79090606120' className='anchor'>+7 (909) 060-61-20</Nav.Link>
                            <Nav.Link href='tel:+79090606121' className='anchor'>+7 (909) 060-61-21</Nav.Link>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <img
                            style={{marginTop: '50px', marginRight: '30px'}}
                            src={Place}
                            height='50'
                            alt=''
                        />
                        <div className='contacts-container__content-place'>
                            <p className='contacts-container__content-place__text'>
                                г. Ижевск,<br/>
                                ул. Воткинское шоссе,<br/>
                                д. 284 М6
                            </p>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <img
                            style={{marginTop: '50px', marginRight: '30px'}}
                            src={Mail}
                            height='50'
                            alt=''
                        />
                        <div className='contacts-container__content-mail'>
                            <Nav.Link
                                className='contacts-container__content-mail__text'
                                href='mailto:grand20.18@bk.ru'
                            >
                                grand20.18@bk.ru</Nav.Link>
                        </div>
                    </div>
                </div>
                <div className='contacts-container__secondContent'>
                    <div className='contacts-container__secondContent-time'>
                        <p className='contacts-container__secondContent-time__text'>
                            Пн-Сб: 8:00–20:00<br/>
                            Вс: Выходной
                        </p>
                    </div>
                    <div className='contacts-container__secondContent-map'>
                        <YMaps>
                            <Map className='contacts-container__secondContent-map__place'
                                 defaultState={{ center: [56.89273031171434,53.34982718650807], zoom: 15.5 }}>
                                <ZoomControl options={{float: 'right'}}/>
                                <Placemark geometry={[56.89273031171434,53.34982718650807]} />
                            </Map>
                        </YMaps>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts;
