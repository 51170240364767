import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import firestore from '../../../api/firestore';
import {ArrowLeft} from "react-bootstrap-icons";
import '../product/style/product.css';
import '../product/style/@media.css';
import {Modal} from "react-bootstrap";
import ModalCallBack from "../../callBackForm/modalCallBack/ModalCallBack";

function Product() {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // Запрос информации о выбранном товаре из первой коллекции
        firestore
            .collection('profile')
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    const productData = doc.data();
                    setProduct(productData);
                    // Первый размер как выбранный, если размеры доступны
                    if (Array.isArray(productData.sizes) && productData.sizes.length > 0) {
                        setSelectedSize(productData.sizes[0]);
                    }
                } else {
                    return firestore.collection('related').doc(id).get();
                }
            })
            .then((secondDoc) => {
                if (secondDoc && secondDoc.exists) {
                    const productData = secondDoc.data();
                    setProduct(productData);
                    // Первый размер как выбранный, если размеры доступны
                    if (Array.isArray(productData.sizes) && productData.sizes.length > 0) {
                        setSelectedSize(productData.sizes[0]);
                    }
                } else {
                    console.log("");
                }
            })
            .catch((error) => {
                console.error('Ошибка при получении данных из Firestore:', error);
            });
    }, [id]);

    const handleSizeChange = (size) => {
        setSelectedSize(size);
    };

    if (!product) {
        return <div>Загрузка...</div>;
    }

    return (
        <div className='product'>
            <div className='product-container'>
                <div className='product-container__flex--one'>
                    <div className='product-container__flex--one-btn'>
                        <button onClick={() => history.goBack()}><ArrowLeft/> Вернуться к товарам</button>
                    </div>
                    <div className='product-container__flex--one-img'>
                        <img src={product.imageUrl} alt={product.name} />
                    </div>
                </div>
                <div className='product-container__flex--two'>
                    <div className='product-container__flex--two-name'>
                        <h2>{product.name}</h2>
                    </div>
                    <div className='product-container__flex--two-container'>
                        {Array.isArray(product.sizes) && product.sizes.length > 0 ? (
                            <div className='product-container__flex--two-container__size'>
                                <h4>Размер:</h4>
                                <select
                                    value={selectedSize}
                                    onChange={(e) => handleSizeChange(e.target.value)}
                                >
                                    {product.sizes.map((size, index) => (
                                        <option key={index} value={size}>
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ) : null}

                        {selectedSize && product.prices ? (
                            <h3>{product.prices[product.sizes.indexOf(selectedSize)]} ₽</h3>
                        ) : null}
                    </div>
                    <div className='product-container__flex--two-btn'>
                        <button onClick={handleShow}>Купить</button>
                    </div>
                    <div className='product-container__flex--two-description'>
                        <p>{product.description}</p>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Форма обратной связи</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ModalCallBack/>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}

export default Product;
